import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./features/Auth.slice";
import CommonReducer from "./features/Common.slice";
import OtpReducer from "./features/Otp.slice";
import ProfileReducer from "./features/Profile.slice";
import GradesReducer from "./features/Grade.slice";
import MessagesReducer from "./features/Messages.slice";
import PickupLocationsReducer from "./features/PickupLocations.slice";
import OrderReducer from "./features/Order.slice";
import OffersReducer from "./features/Offers.slice";
import OffersActionsReducer from "./features/OffersActions.action";
import DraftReducer from "./features/Draft.slice";
import NotificationsReducer from "./features/Notification.slice";
import PricingReducer from "./features/Pricing.slice";
import MakeYourSaleReducer from "./features/MakeYourSale.slice";
import CreateOfferOrderFormReducer from "./features/CreateOfferOrderFormReducer.slice";
const store = configureStore({
  reducer: {
    AuthReducer,
    CommonReducer,
    OtpReducer,
    ProfileReducer,
    GradesReducer,
    MessagesReducer,
    PickupLocationsReducer,
    OrderReducer,
    OffersReducer,
    OffersActionsReducer,
    DraftReducer,
    NotificationsReducer,
    CreateOfferOrderFormReducer,
    PricingReducer,
    MakeYourSaleReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
