import { IOrderStatusList } from "../interfaces/OrdersInterface";
import { page_view_events } from "./eventTrackingConstants";

export const ApiURL = {
  COMPANY_LIST:
    "public/vendors?sort=status&size=30&page=1&fields=id,name,address,state,zip_code,city,state_id",
  STATE_LIST: "public/state",
  PICKUP_LOCATION: "account/pickup_location/",
  PROFILE_DETAILS: "account/me",
  PROFILE_WALKTHROUGH: "account/update_walkthrough",
  UPDATE_PROFILE_DETAILS: "account/",
  TOP_GRADES_LIST: "grade/?region=",
  YOUR_GRADES_LIST: "grade/preferred_grade/",
  UPDATE_NOTIFICATION_SETTINGS: "account/notification/",
  GRADE_CATEGORY: "grade/category",
  GRADE_LIST: "grade/explore",
  GRADE_DETAILS_V2: "grade/v2",
  USER_MESSAGE_SESSION: "messages/user/session",
  USER_ACCOUNT_VERIFY: "public/validation/email",
  ORDER_LIST: "trade/order/",
  OFFERS_LIST: "trade/offer",
  GRADE_FILTER_LIST: "trade/offer/grade_list",
  MESSAGE_USER_LIST: "messages/users?admin_details=true",
  MESSAGE_QUESTIONS: "messages/chatbot/questions",
  CLOUD_FRONT_URL: process.env.REACT_APP_USER_S3_CLOUDFRONT_URL,
  LOCATION_FILTER_LIST: "trade/offer/location_list",
  CITY_FILTER_LIST: "trade/offer/city_list",
  ORDER_GRADE_FILTER_LIST: "trade/order/grade_list",
  ORDER_LOCATION_FILTER_LIST: "trade/order/location_list",
  ORDER_CITY_FILTER_LIST: "trade/order/city_list",
  ORDER_CREATION_DESIRED_LOCATION: "trade/location/port",
  CREATE_ORDER: "trade/order/",
  PORTS_LIST: "trade/location/port",
  DESTINATION_LIST: "trade/location/gp_destinations",
  NOTIFICATION_LIST: "account/notification/?size=10",
  NOTIFICATION_UPDATE: "account/notification/",
  ZIP_CODE_VERIFICATION: "public/validation/zip_code",
  CREATE_OFFER: "trade/offer/",
  CREATE_ORDER_DRAFT: "trade/order/draft/",
  CREATE_OFFER_DRAFT: "trade/offer/draft/",
  CREATE_GRADE: "grade/",
  ORDER_STATUS_FILTER: "trade/order/status_list",
  OFFER_STATUS_FILTER: "trade/offer/status_list",
  MAIL_MESSAGE: "account/notification/messages",
  GTM_TRACK_LIST: "trade/offer/gtm_track_list",
  MYS_FORM_API: "grade/v2/price/",
  MYS_FORM_SUBMIT: "trade/v2/sale",
  MYS_DRAFT: "trade/v2/draft",
  DRAFT_LISTING: "trade/order/?size=10&is_draft=true&",
  DRAFT_DETAILS: "trade/order/",
  COUNTER_OFFER: "/trade/offer/counter",
  APP_ALERTS: "/account/notification/app_alerts",
};

export const ToastTypes = {
  SUCCESS: "success",
  ERROR: "error",
};

export const ErrorCodes = {
  UNAUTHORIZED: 401,
  BADREQUEST: 400,
  SERVER_ERR: 500,
  PERMISSION_DENIED: 403,
};

export const SCREEN_TYPE = {
  DESKTOP: "DESKTOP",
  LAPTOP: "LAPTOP",
  MOBILE: "MOBILE",
  TABLET: "TABLET",
  SMALLER_MOBILE: "SMALLER_MOBILE",
};

export const GradeListType = {
  PREFERRED: "preferred",
  ALL: "all",
};

export const EXCLUDED_API_URL = [
  ApiURL.COMPANY_LIST,
  ApiURL.STATE_LIST,
  ApiURL.USER_ACCOUNT_VERIFY,
  ApiURL.ZIP_CODE_VERIFICATION,
];

export const maxImageSize = 1024 * 1024 * 1.5;
export const maxVideoSize = 1024 * 1024 * 10;
export const SHIPMENT_MODES = [
  {
    label: "FAS Export",
    shipmentText: "You Deliver",
  },
  {
    label: "DAP Domestic",
    shipmentText: "You Deliver",
  },
  {
    label: "FOB Export",
    shipmentText: "We Pick Up",
  },
  {
    label: "FOB Domestic",
    shipmentText: "We Pick Up (FOB)",
  },
];

export const HTTPMethods = {
  GET: "get",
  POST: "post",
  PUT: "put",
};

export const offer_status_list = {
  "Waiting for Supplier": "Action Needed",
  "Waiting for Trader": "Waiting for Trader",
  Canceled: "Canceled",
  Declined: "Declined",
  Expired: "Expired",
  Accepted: "Accepted",
};
export const order_status_list: IOrderStatusList = {
  NEW: "New",
  CANCELED: "Canceled",
  COMPLETED: "Completed",
  DELIVERY_IN_PROGRESS: "Delivery in progress",
  PAYMENT_IN_PROGRESS: "Payment in progress",
  PAYMENT_COMPLETE: "Payment processed",
  DELIVERED: "Delivered",
};
export const passwordSupportChar =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[[\]^{}"/|;:.,~!?@#$%^=*\-+_\\])[A-Za-z\d[\]^{}"/|;:.,~!?@#$%^=*\-+_\\]{8,}$/;
export const UserPermissibleRoles = ["supplier"];

export const status = {
  accepted: "Accepted",
  canceled: "Canceled",
  expired: "Expired",
  declined: "Declined",
  waiting_for_trader: "Waiting for Trader",
  waiting_for_supplier: "Waiting for Supplier",
  action_needed: "Action Needed",
};

export const SHIPMENT_TYPE = {
  FOB: "FOB Export",
  FAS: "FAS Export",
  DAP: "DAP Domestic",
  FOBD: "FOB Domestic",
};

export const FORM_FLOW = {
  DRAFT: "draft",
  CREATE: "create",
  SELL_AGAIN: "sell-again",
  OFFER_AGAIN: "offer-again",
  OUT_OF_LIST: "out-of-list",
};
export const FORM_TYPE = {
  ORDER: "order",
  OFFER: "offer",
  COUNTER_OFFER: "counter-offer",
  ACCEPTED_OFFER: "accepted-offer",
};
export const shipment_types = {
  FOB_DOMESTIC: "FOB Domestic",
  FAS_DOMESTIC: "DAP Domestic",
  FOB_EXPORT: "FOB Export",
  FAS_EXPORT: "FAS Export",
};
export const createOfferOrderFrom = {
  GRADES: "grades",
  DEALS: "deals",
};

export const offer_shipment_types = {
  FOB_DOMESTIC: "FOB_DOMESTIC",
  FAS_DOMESTIC: "FAS_DOMESTIC",
  FOB_EXPORT: "FOB_EXPORT",
  FAS_EXPORT: "FAS_EXPORT",
  DAP_DOMESTIC: "DAP_DOMESTIC",
};

export const price_modes = {
  requested_price_label: "Requested Price",
  request_price: "request-price",
  propose_price: "propose-price",
};
export const emailSupportChar =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const HELP_FAQ_URL = process.env.REACT_APP_HELP_URL;
export const LOAD_INFO_MAP = {
  CONTAINER: "Container",
  RAIL: "Rail",
  TRUCK: "Truck",
};

export const LOAD_INFO_MAP_FOR_REVIEW = new Map([
  ["Container", "Ship Container"],
]);

export const USER_NOT_FOUND = "User not found";

export const PP_URL = "https://privacypolicy.kochind.com/";
export const TNC_URL =
  "https://www.gapacrecycling.com/wp-content/uploads/2020/11/GP-Recycling-Mobile-Application-Terms-and-Conditions.pdf";

export const NOT_VALID_USER = "Not a valid user";

export const BACK_BUTTON_PAGES = {
  ORDERS: "Orders",
  OFFERS: "Offers",
};
export const support = {
  mail: "hubbit@gapac.com",
  tel: "404-735-9536",
};

export const CUSTOM_ERROR_MSG = {
  DEAL: "Deal not found!!!",
  GRADE: "Grade not found!!!",
  OFFER: "You cannot view this Price Request!",
  ORDER: "You cannot view this Sale!",
  OFFER_PERMISSION_DENIED: "You cannot view this Price Request!",
  ORDER_PERMISSION_DENIED: "You cannot view this Sale!",
};

export const allowedVideoFormat = ["video/mp4", "video/m4v", "video/mov"];

export const allowedImageFormat = [
  "image/jpeg",
  "image/png",
  "image/jpg",
  "image/gif",
  "image/bmp",
];
export const emptyTableText =
  "Once you submit an price request, you can track the status here. Click on Grades to find your material and submit your price request";
export const searchFailText =
  "Sorry. We couldn't find any matches. Double check your search for any typos or spelling errors - or try a different search term. Also keep in mind, our team is a click away. Reach out to us if you need further help";
export const filterFailText =
  "Sorry. We couldn't find any results matching your filters. Also keep in mind, our team is a click away. Reach out to us if you need further help";
export const emptyTableTextDraft = "No Draft Available";
export const EXCLUDED_SERVER_ERR_URL = [
  ApiURL.USER_MESSAGE_SESSION,
  ApiURL.MESSAGE_USER_LIST,
];

export const roles = {
  ADMIN: "Admin",
  TRADER: "Trader",
  SUPPLIER: "SUPPLIER",
};

export const FAS_DOMESTIC_NAMES = {
  fas_domestic: "fas domestic",
  dap_domestic: "DAP Domestic",
};
export const pageViewEventRoutes = [
  {
    path: "/home/index",
    name: page_view_events.VIEW_HOME,
  },
  {
    path: "/home/grades",
    name: page_view_events.VIEW_GRADES,
  },
  {
    path: "/home/deals",
    name: page_view_events.VIEW_DEALS,
  },
];
export const integer_regex = /^[0-9]*$/;

export const forbiddenUser = {
  status: 401,
  error: "INVALID USER ACTION",
  message: "FORBIDDEN",
  errorMessage: "Access has been revoked. Kindly contact Admin.",
};

export const messageDateLimit = 90;

export const chatbot_dailog_filter = ["chatbot_web", "chatbot_mobile"];
